<template>
  <div>
    <ElForm
      v-for="document in documentNumbers"
      :key="document.uuid"
      :ref="(el) => (formRefs[document.uuid] = el)"
      :model="document"
      require-asterisk-position="right"
      :rules="createDocumentRules(document)"
      label-position="top"
    >
      <ElRow :gutter="20">
        <ElCol :span="columnSizes.span" :lg="columnSizes.lg">
          <ElFormItem prop="type" :label="$t('document_type')">
            <ElSelect
              v-model="document.country"
              placeholder=""
              style="width: 70px; display: inline-block"
              filterable
              :class="background ? 'prepend' : 'no-background prepend'"
              :fallback-placements="['top', 'bottom']"
              :popper-options="getSelectPopperOptions()"
              popper-class="custom-selector"
              @change="updateDocumentTracking(document)"
            >
              <template #prefix>
                <img
                  v-if="selectedDocumentTypeCountry(document.country)"
                  :src="selectedDocumentTypeCountry(document.country).flag"
                />
              </template>
              <ElOptionGroup v-for="(group, index) in countries" :key="index">
                <ElOption
                  v-for="item in group.options"
                  :label="`${item.label}`"
                  :value="item.iso_code"
                  :key="item.iso_code"
                >
                  <template #default>
                    <img :src="item.flag" :alt="item.iso_code" />
                    <span>{{ item.iso_code }}</span>
                  </template>
                </ElOption>
              </ElOptionGroup>
            </ElSelect>
            <ElSelect
              v-model="document.type"
              :placeholder="$t('select_a_document_type')"
              style="width: calc(100% - 70px); display: inline-block"
              :class="background ? 'append' : 'no-background append'"
              filterable
              :fallback-placements="['top', 'bottom']"
              :popper-options="getSelectPopperOptions()"
              popper-class="custom-selector"
              @change="updateDocumentTracking(document)"
            >
              <ElOption
                v-for="item in documentTypesArray"
                :label="`${t('document_types.' + item.label.toLowerCase())}`"
                :value="item.value"
                :key="item.value"
                :disabled="isDocumentTypeUsed(item.value, document)"
              />
            </ElSelect>
          </ElFormItem>
        </ElCol>
        <ElCol :span="columnSizes.span" :lg="columnSizes.lg">
          <ElFormItem prop="number" :label="$t('document_number')">
            <ElInput
              v-model="document.number"
              :class="background ? 'prepend' : 'no-background prepend'"
              @change="updateDocumentTracking(document)"
            />
          </ElFormItem>
        </ElCol>
        <ElCol :span="columnSizes.span" :lg="columnSizes.lg">
          <ElFormItem prop="status" :label="$t('document_status')">
            <ElSelect
              v-model="document.status"
              :placeholder="$t('select_an_option')"
              :autocomplete="true"
              style="width: 100%"
              :class="background ? 'prepend' : 'no-background prepend'"
              filterable
              :fallback-placements="['top', 'bottom']"
              :popper-options="getSelectPopperOptions()"
              popper-class="custom-selector"
              clearable
              clear-icon="Close"
              disabled
              @change="updateDocumentTracking(document)"
            >
              <ElOption
                v-for="item in documentStatuses"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </ElSelect>
          </ElFormItem>
        </ElCol>
        <ElCol :span="columnSizes.span" :lg="columnSizes.lg">
          <ElFormItem prop="issue_date" :label="$t('issue_date')">
            <ElDatePicker
              v-model="document.issue_date"
              class="!w-full"
              :class="background ? 'prepend' : 'no-background prepend'"
              type="date"
              format="DD/MM/YYYY"
              popper-class="custom-datepicker"
              :editable="false"
              :clearable="false"
              :disabled-date="disabledIssueDates"
              @change="handleIssueDateChange(document)"
            />
          </ElFormItem>
        </ElCol>
        <ElCol :span="columnSizes.span" :lg="columnSizes.lg">
          <ElFormItem prop="expiry_date" :label="$t('expiry_date')">
            <ElDatePicker
              v-model="document.expiry_date"
              class="!w-full"
              :class="background ? 'prepend' : 'no-background prepend'"
              type="date"
              format="DD/MM/YYYY"
              popper-class="custom-datepicker"
              :editable="false"
              :clearable="false"
              :disabled-date="(date) => disabledExpiryDates(date, document)"
              @change="updateDocumentTracking(document)"
            />
          </ElFormItem>
        </ElCol>
      </ElRow>
      <div class="document-remove" v-if="documentNumbers.length > 1">
        <Icons
          name="24px/trash_red"
          class="cursor-pointer"
          @click="showDeleteDialog(document.uuid)"
        >
          {{ $t('remove') }}
        </Icons>
      </div>
    </ElForm>
    <ElRow :gutter="20" v-if="documentNumbers.length < documentStatuses.length">
      <ElCol :span="24">
        <ElButton
          type="link"
          @click="addNewDocument()"
          style="margin-top: 24px"
        >
          {{ $t('add_another_document') }}
        </ElButton>
      </ElCol>
    </ElRow>
  </div>
</template>

<script>
import moment from 'moment'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { cloneDeep } from 'lodash'
import { ElLoading, ElMessageBox } from 'element-plus'
import DELETE_DOCUMENT from '~/graphql/documents/mutation/deleteDocument.gql'

export default defineComponent({
  props: {
    documents: {
      type: Array,
      default: () => []
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    background: {
      type: Boolean,
      default: false
    },
    columnSizes: {
      type: Object,
      default: () => ({
        span: 24,
        lg: 12
      })
    }
  },
  setup(props) {
    const { t } = useI18n()
    const ruleFormRef = ref()
    const { width } = useBreakpoints()
    const { $showError, $showSuccess } = useNuxtApp()

    return {
      t,
      $showError,
      $showSuccess,
      ruleFormRef,
      width
    }
  },
  data() {
    return {
      loadingDialog: null,
      documentNumbers: [],
      countries: getModelCountries(),
      documentTypesArray: getDocumentTypes(),
      documentStatuses: getDocumentStatuses(),
      documentsToCreateOrUpdateOrDelete: [],
      formRefs: {},
      requiredFields: ['type', 'number'],
    }
  },
  created() {
    if (this.documents && this.documents?.length > 0) {
      this.documentNumbers = cloneDeep(this.documents)
    } else {
      this.addNewDocument()
    }
  },
  methods: {
    createDocumentRules(document) {
      const documentNumberElForm = (rule, value, callback) => {
        if (!value) {
          callback()
          return
        }

        if (document?.type === 'NATIONAL_ID') {
          if (['ES', 'FR'].includes(document.country)) {
            if (
              !documentNumber(value, {
                document_type_country: document.country,
                cif_country: value
              })
            ) {
              callback(new Error(this.$t('document_number_not_valid')))
              return
            }
          } else {
            if (
              !documentNumber(value, {
                document_type_country: 'OT',
                cif_country: value
              })
            ) {
              callback(new Error(this.$t('document_number_not_valid')))
              return
            }
          }
        }

        const duplicateNumber = this.documentNumbers.find(
          (doc) => doc?.uuid !== document?.uuid && doc?.number === value
        )

        if (duplicateNumber) {
          callback(new Error(this.$t('document_number_already_exists')))
          return
        }

        callback()
      }

      const issueDateElForm = (rule, value, callback) => {
        if (!value) {
          callback()
          return
        }

        if (moment(value).isAfter(moment())) {
          callback(new Error(this.$t('issue_date_cannot_be_future')))
          return
        }

        callback()
      }

      const expiryDateElForm = (rule, value, callback) => {
        if (!value && !document?.issue_date) {
          callback()
          return
        }

        if (moment(value).isBefore(moment(document?.issue_date))) {
          callback(new Error(this.$t('start_date_cannot_be_after_end_date')))
          return
        }

        callback()
      }

      const baseRules = {
        type: [
          {
            required: true,
            message: this.$t('document_type_is_required'),
            trigger: ['change', 'blur']
          }
        ],
        number: [
          {
            required: true,
            message: this.$t('document_number_is_required'),
            trigger: ['change', 'blur']
          },
          {
            validator: documentNumberElForm,
            trigger: ['change', 'blur']
          }
        ],
        issue_date: [
          {
            validator: issueDateElForm,
            trigger: ['change', 'blur']
          }
        ],
        expiry_date: [
          {
            validator: expiryDateElForm,
            trigger: ['change', 'blur']
          }
        ]
      }

      if (this.isRequired && this.documentNumbers.indexOf(document) === 0) {
        return baseRules
      }

      return Object.keys(baseRules).reduce((acc, key) => {
        acc[key] = [
          {
            validator: (rule, value, callback) => {
              const hasAnyValue = Object.entries(document).some(
                ([fieldName, fieldValue]) =>
                  fieldName !== 'country' &&
                  fieldName !== 'uuid' &&
                  fieldName !== 'front_file' &&
                  fieldName !== 'back_file' &&
                  fieldValue
              )

              if (!hasAnyValue) {
                callback()
                return
              }

              if (!value && this.requiredFields.indexOf(key) !== -1) {
                callback(new Error(this.$t(`document_${key}_is_required`)))
                return
              }

              if (key === 'number' && value) {
                documentNumberElForm(rule, value, callback)
              } else if (key === 'issue_date' && value) {
                issueDateElForm(rule, value, callback)
              } else if (key === 'expiry_date' && value) {
                expiryDateElForm(rule, value, callback)
              } else {
                callback()
              }
            },
            trigger: ['change', 'blur']
          }
        ]
        return acc
      }, {})
    },
    selectedDocumentTypeCountry(value) {
      for (const elem of this.countries) {
        const country = elem.options.find((item) => item.iso_code === value)
        if (country) {
          return country
        }
      }
      return null
    },
    async addNewDocument() {
      const isValid = await this.validateAllDocuments()

      if (!isValid) {
        this.$showError(this.$t('please_fix_existing_document_numbers'))
        return
      }

      const newDocumentNumber = {
        uuid: String(Math.random()).slice(2, 10),
        type: null,
        country: 'US',
        number: null,
        status: 'ACTIVE',
        issue_date: null,
        expiry_date: null,
        front_file: {
          connect: null
        },
        back_file: {
          connect: null
        }
      }

      this.documentsToCreateOrUpdateOrDelete.push({
        create: newDocumentNumber
      })

      this.documentNumbers.push(newDocumentNumber)
    },
    isNewDocument(document) {
      return !this.documents.some((d) => d.uuid === document.uuid)
    },
    hasDocumentChanged(current, original) {
      return [
        'type',
        'country',
        'number',
        'issue_date',
        'expiry_date'
      ].some((field) => current[field] !== original[field])
    },
    showDeleteDialog(uuid) {
      if (uuid !== null) {
        ElMessageBox.confirm(
          this.$t('changes_cannot_be_undone'),
          this.$t('are_you_sure_you_want_to_delete'),
          {
            confirmButtonText: this.$t('delete'),
            cancelButtonText: this.$t('cancel'),
            confirmButtonClass: 'el-button--primary',
            cancelButtonClass: 'el-button--secondary',
            customClass: 'custom-delete-box'
          }
        )
          .then(async () => {
            try {
              if (this.documentNumbers.length > 1) {
                const index = this.documentNumbers.findIndex(
                  (d) => d.uuid === uuid
                )
                if (index !== -1) {
                  this.documentNumbers.splice(index, 1)
                }
              }

              if (!this.isNewDocument({ uuid })) {
                this.loadingDialog = ElLoading.service({ fullscreen: true })
                const response = await mutation(DELETE_DOCUMENT, {
                  uuid: uuid
                })
                if (response.error) {
                  this.$showError(response.error, this.t)
                  this.$sentry(response.error, 'showDeleteDialog')
                }
              } else {
                const createIndex =
                  this.documentsToCreateOrUpdateOrDelete.findIndex(
                    (d) => d?.create && d?.create?.uuid === uuid
                  )

                if (createIndex !== -1) {
                  this.documentsToCreateOrUpdateOrDelete.splice(createIndex, 1)
                }
              }

              this.$showSuccess(this.$t('delete_entity_success'))
            } catch (err) {
              this.$showError(err, this.t)
              this.$sentry(err, 'showDeleteDialog')
            } finally {
              this.loadingDialog.close()
            }
          })
          .catch(() => {})
      }
    },
    async updateDocumentTracking(document) {
      document.status = this.calculateDocumentStatus(document?.expiry_date)

      const isValid = await this.validateDocument(document)
      if (!isValid) return

      const duplicateType = this.documentNumbers.find(
        (doc) => doc.uuid !== document.uuid && doc.type === document.type
      )

      if (duplicateType) {
        this.$showError(this.$t('document_type_already_exists'))
        return
      }

      if (this.isNewDocument(document)) {
        const createIndex = this.documentsToCreateOrUpdateOrDelete.findIndex(
          (d) => d?.create && d?.create?.uuid === document.uuid
        )

        const createdOrUpdatedDocument = {
          uuid: document?.uuid ?? String(Math.random()).slice(2, 10),
          type: document?.type ?? null,
          country: document?.country ?? null,
          number: document?.number ?? null,
          issue_date: document?.issue_date
            ? moment(document.issue_date).format('YYYY-MM-DD')
            : null,
          expiry_date: document?.expiry_date
            ? moment(document.expiry_date).format('YYYY-MM-DD')
            : null,
          front_file: document?.front_file?.connect
            ? { connect: document?.front_file?.connect }
            : null,
          back_file: document?.back_file?.connect
            ? { connect: document?.back_file?.connect }
            : null
        }

        if (createIndex === -1) {
          this.documentsToCreateOrUpdateOrDelete.push({
            create: createdOrUpdatedDocument
          })
        } else {
          this.documentsToCreateOrUpdateOrDelete[createIndex].create =
            createdOrUpdatedDocument
        }
      } else {
        const originalDoc = this.documents.find((d) => d.uuid === document.uuid)

        if (this.hasDocumentChanged(document, originalDoc)) {
          const updateIndex = this.documentsToCreateOrUpdateOrDelete.findIndex(
            (d) => d?.update && d?.update?.uuid === document.uuid
          )

          const updateData = {
            uuid: document.uuid
          }

          if (document.type !== originalDoc.type)
            updateData.type = document.type
          if (document.country !== originalDoc.country)
            updateData.country = document.country
          if (document.number !== originalDoc.number)
            updateData.number = document.number
          if (document.issue_date !== originalDoc.issue_date)
            updateData.issue_date = document?.issue_date
              ? moment(document.issue_date).format('YYYY-MM-DD')
              : null
          if (document.expiry_date !== originalDoc.expiry_date)
            updateData.expiry_date = document?.expiry_date
              ? moment(document.expiry_date).format('YYYY-MM-DD')
              : null

          if (updateIndex === -1) {
            this.documentsToCreateOrUpdateOrDelete.push({
              update: updateData
            })
          } else {
            this.documentsToCreateOrUpdateOrDelete[updateIndex].update =
              updateData
          }
        }
      }

      const documents = this.documentsToCreateOrUpdateOrDelete
        .map((doc) => {
          if (doc.create) {
            const { uuid, ...createData } = doc.create

            const hasAllRequiredFields = this.requiredFields.every(
              (field) => createData[field]
            )

            if (!hasAllRequiredFields) {
              return null
            }

            return {
              create: {
                ...createData,
                issue_date: createData.issue_date
                  ? moment(createData.issue_date).format('YYYY-MM-DD')
                  : null,
                expiry_date: createData.expiry_date
                  ? moment(createData.expiry_date).format('YYYY-MM-DD')
                  : null
              }
            }
          } else if (doc.update) {
            return {
              update: {
                uuid: doc.update.uuid,
                ...doc.update
              }
            }
          }

          return doc
        })
        .filter(Boolean)

      if (documents.length) {
        this.$emit('documentsToCreateOrUpdateOrDelete', documents)
      }
    },
    async validateDocument(document) {
      if (this.formRefs[document.uuid]) {
        try {
          await this.formRefs[document.uuid].validate()
          return true
        } catch (error) {
          return false
        }
      }
      return true
    },
    async validateAllDocuments() {
      if (!this.documentNumbers.length) return true

      if (this.isRequired) {
        const firstDocumentNumber = this.documentNumbers[0]
        const isFirstDocumentNumberComplete = this.requiredFields.every(
          (field) => firstDocumentNumber[field]
        )

        if (!isFirstDocumentNumberComplete) {
          return false
        }
      }

      const validations = await Promise.all(
        this.documentNumbers.map((doc) => this.validateDocument(doc))
      )

      return validations.every((isValid) => isValid)
    },
    isDocumentTypeUsed(type, currentDocument) {
      return this.documentNumbers.some(
        (doc) => doc.uuid !== currentDocument.uuid && doc.type === type
      )
    },
    disabledIssueDates(date) {
      return date > new Date()
    },
    disabledExpiryDates(date, document) {
      if (!document.issue_date) return true
      return date < document.issue_date
    },
    handleIssueDateChange(document) {
      if (
        document.expiry_date &&
        moment(document.issue_date).isAfter(document.expiry_date)
      ) {
        document.expiry_date = document.issue_date
      }

      this.updateDocumentTracking(document)
    },
    calculateDocumentStatus(expiryDate) {
      if (!expiryDate) return 'ACTIVE'
      
      const today = moment()
      const expiry = moment(expiryDate)
      const daysUntilExpiry = expiry.diff(today, 'days')

      if (daysUntilExpiry < 0) {
        return 'EXPIRED'
      } else if (daysUntilExpiry <= 14) {
        return 'EXPIRING_SOON'
      } else {
        return 'ACTIVE'
      }
    },
  }
})
</script>

<style scoped lang="scss">
.el-form {
  position: relative;
  &:not(:last-of-type) {
    border-bottom: 1px solid #eaecf0;
    padding-bottom: 24px;
    &.background {
      border-bottom: 1px solid var(--brand-off-white) !important;
    }
  }
  .el-row {
    width: calc(100% - 8px - 24px);
    margin-right: 8px;
  }
  .document-remove {
    position: absolute;
    top: 20px;
    right: 0;
  }
}
</style>